<template>
	<div id="swiper">
		<vue-seamless-scroll :data="carouselArr" :class-option="classOption" class="warp ">
			<ul class="ul-item">
				<li class="li-item" :class="['lv'+item.lv]" v-for="(item, index) in carouselArr" :key="index">
					<a :href="item | ToRoute" class="li-item-a">
						<img v-show="item.type == 3" class="Vsicon" src="@/assets/images/public/Vsicon.png" alt="">
						<div class="li-item-main">
							<div class="img"><img :src="item.cover" alt=""></div>
							<!-- <p>{{item.box_name | StrInterceptT}}</p> -->
							<span style="color:#fff">{{item.name | StrInterceptS}}</span>
						</div>
						<!-- <div class="">
							<div class="warp-item-a-img">
								<img :src="item.user.avatar" alt="">
							</div>
							<p>{{item.user.name | StrIntercept}}</p>
						</div> -->
					</a>
				</li>
			</ul>
		</vue-seamless-scroll>

	</div>
</template>

<script>
	import {
		Swiper,
		SwiperSlide
	} from 'vue-awesome-swiper'
	import 'swiper/css/swiper.css'
	import {
		UnpackingRecord
	} from '@/network/api.js'
	import vueSeamlessScroll from 'vue-seamless-scroll'
	export default {
		name: 'headerSwiper',
		data() {
			return {
				classOption: {
					limitMoveNum: 1,
					direction: 3,
					// autoPlay: false
				},
				carouselArr: [], //历史物品掉落记录
				swiperOption: {
					// 自动轮播
					autoplay: {
						delay: 5000
					},
					//开启循环模式
					loop: true,
					// 网格分布一屏两个及走两个
					slidesPerView: 2,
					slidesPerGroup: 2,

				}
			}
		},
		filters: {
			StrIntercept(val) {
				return val.length > 4 ? '...' + val.substr(0, 3) : val
			},
			StrInterceptT(val) {
				return val.length > 4 ? val.substr(0, 4) + '...' : val
			},
			StrInterceptS(val) {
				return val.length > 7 ? val.substr(0, 6) + '...' : val
			},
			//路由过滤
			ToRoute(v) {
				if (v.type == '1') {
					return '/Open?id=' + v.box_id
				} else if (v.type == '2') {
					return 'Activity'
				} else if (v.type == '3') {
					return '/Battle'
				} else if (v.type == '4') {
					return '/Lucky'
				} else if (v.type == '5') {
					return '/Mall'
				}

			}
		},

		created() {
			this.GetUnpackingRecord()
			this.TenSeconds()
		},

		methods: {
			//开奖记录
			GetUnpackingRecord() {
				let delay = localStorage.getItem('delay') ? Number(localStorage.getItem('delay')) : 0
				UnpackingRecord({
					delay
				}).then((res) => {
					this.carouselArr = res.data.data.historylist
				})
			},
			//定时刷新
			TenSeconds() {
				setInterval(() => {
					this.GetUnpackingRecord()
				}, 10000)
			}
		},
		components: {
			Swiper,
			SwiperSlide,
			vueSeamlessScroll
		}
	}
</script>

<style lang="scss">
	.Vsicon {
		width: 15px;
		position: absolute;
		right: 0;
		top: 2px;
	}

	.warp {

		& .li-item {
			margin: 0 2px;
			width: 105px;
			height: 68px;
			flex-shrink: 0;
			background-size: 100% 100%;
			.li-item-a{
				width: 100%;
				height: 100%;
			}
			.li-item-main{
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				.img{
					width: 63px;
					height: 42px;
					display: flex;
					align-items: center;
					justify-content: center;
					img{
						height: 100%;
					}
				}
				p{
					display: block;
					text-overflow: ellipsis;
					overflow: hidden;
					width: 100%;
					font-size: 10px;
					text-align: center;
				}
				span{
					display: block;
					text-overflow: ellipsis;
					overflow: hidden;
					width: 100%;
					font-size: 12px;
					text-align: center;
				}
			}
			&.lv1{
				background-image: url(~@/assets/img/product/lv-bg-1.png);
				p{
					// color: #F2BC34;
				}
			}
			&.lv2{
				background-image: url(~@/assets/img/product/lv-bg-2.png);
				p{
					// color: #FD4453;
				}
			}
			&.lv3{
				background-image: url(~@/assets/img/product/lv-bg-3.png);
				p{
					// color: #FC56FF;
				}
			}
			&.lv4{
				background-image: url(~@/assets/img/product/lv-bg-4.png);
				p{
					// color: #61E1FD;
				}
			}
			&.lv5{
				background-image: url(~@/assets/img/product/lv-bg-5.png);
				p{
					// color: #D0D0D0;
				}
			}
		}

		ul {
			list-style: none;
			padding: 0;
			margin: 0 auto;

			&.ul-item {
				display: flex;
			}
		}
	}

	#swiper {
		width: 100%;
		box-sizing: border-box;
		background: rgba($color: #121319, $alpha: .8);
		box-sizing: border-box;
		padding: 6px 0;
		.swiper-container {
			width: 100%;
			height: 100%;

			.swiper-slide {
				width: 50% !important;
				height: 100%;
				padding: 8px 4px;
				box-sizing: border-box;

				.swiper-slide_box {
					display: block;
					width: 100%;
					height: 100%;
					background: rgba(0, 0, 0, 0.2);
					border: 1px solid rgba($color: #242632, $alpha: 1);
					border-radius: 4px;
					padding: 0 5px;
					box-sizing: border-box;
					display: flex;
					justify-content: space-between;
					position: relative;

					.Vsicon {
						width: 15px;
						position: absolute;
						right: 0;
						top: 2px;
					}

					.left {
						width: 70%;
						height: 100%;
						padding: 0 2px;
						box-sizing: border-box;
						display: flex;
						justify-content: space-between;
						align-items: center;

						.pic {
							width: 40px;
							height: 40px;
							background-size: 100% 100% !important;
							background-position: center !important;
							background-repeat: no-repeat !important;
							display: flex;
							justify-content: center;
							align-items: center;

							img {
								vertical-align: middle;
								max-width: 100%;
								max-height: 100%;
							}
						}

						.pic_data {
							width: 60%;
							height: 35px;
							padding-left: 2px;
							box-sizing: border-box;

							p {
								width: 100%;
								height: 20px;
								color: #d42d4f;
								font-size: 12px;
								font-weight: bold;
								white-space: nowrap;
								overflow: hidden;
								line-height: 20px;
								margin: 0;
							}

							span {
								display: block;
								width: 100%;
								height: 15px;
								font-size: 10px;
								color: #fff;
							}
						}
					}

					.right {
						width: 30%;

						.pic {
							width: 25px;
							height: 25px;
							border-radius: 50%;
							overflow: hidden;
							border: 1px solid #ffba2e;
							box-sizing: border-box;
							margin: 8px auto 5px;

							img {
								width: 100%;
								height: 100%;
							}
						}

						p {
							margin: 0;
							font-size: 10px;
							color: #ffba2e;
							text-align: center;
						}
					}
				}
			}
		}
	}

	.warp-item-a {
		.warp-item-a-btn {
			.pic {
				width: 60px;
				height: 60px;
				display: flex;
				justify-content: center;
				align-items: center;

				img {
					width: 60px;
				}
			}

			span {
				display: block;
				margin: 0 auto;
				text-align: center;
			}
		}
	}
</style>

<template>
	<div id="Index">
		<pub-header></pub-header>
		<div class="my-swipe-con" v-if="name=='Home'">
			<van-swipe class="my-swipe" :autoplay="8000" indicator-color="white" ref="swipe">
				<van-swipe-item>
					<img class="banner-img" @click="skipTo('/Double')" src="@/assets/img/banner0.png" alt="">
				</van-swipe-item>
				<van-swipe-item>
					<img class="banner-img" src="@/assets/img/banner4.png" alt="">
				</van-swipe-item>
				<van-swipe-item>
					<img class="banner-img" src="@/assets/img/banner3.png" alt="">
				</van-swipe-item>
				<!-- <van-swipe-item>
					<img class="banner-img" src="@/assets/img/banner2.png" alt="">
				</van-swipe-item> -->
			</van-swipe>
			<div class="btn" @click="slideTo('prev')"></div>
			<div class="btn right" @click="slideTo('next')"></div>
		</div>

		<div class="nav-con" v-if="name!='ReplacementRecord' && name!='Bill' && name!='vipActive' && name!='Me' && name!='Warehouse' && name!='Extension' && name!='Changepass' && name!='Recharge' && name!='Help' && name!='HelpCom' && name!='Article' && name!='Double'">
			<div class="item" :class="{'active': name=='Battle' || name=='Home' || name=='Combat' || name=='Battlehistory'}" @click="skipTo('/Battle')">
				<img src="~@/assets/img/nav-fight.png" alt="">
				<span>盲盒对战</span>
			</div>
			<div class="item" :class="{'active': name=='Replacement' || name=='Home'}" @click="skipTo('/Replacement')">
				<img src="~@/assets/img/nav-luck.png" alt="">
				<span>幸运饰品</span>
			</div>
			<div class="item" :class="{'active': name=='Roll' || name=='Home'}" @click="skipTo('/Roll')">
				<img src="~@/assets/img/nav-roll.png" alt="">
				<span>ROLL福利</span>
			</div>
			<div class="item" :class="{'active': name=='VIP' || name=='Home'}" @click="skipTo('/VIP')">
				<img src="~@/assets/img/nav-vip.png" alt="">
				<span>会员中心</span>
			</div>
		</div>

		<pub-swiper v-if="name=='Home' || name=='Open'"></pub-swiper>
		<router-view class="Router_View" />
		<back-top></back-top>
		<broad-side></broad-side>
		<pub-footer v-if="showFooter"></pub-footer>
		<div class="nav-bottom-con" v-if="name!='Warehouse'">
			<div class="item" :class="{'active': name=='Home'}" @click="skipTo('/Home')">
				<div class="img home"></div>
				<span>首页</span>
			</div>
			<div class="item" :class="{'active': name=='Mall'}" @click="skipTo('/Mall')">
				<div class="img shop"></div>
				<span>商城</span>
			</div>
			<div class="item" style="opacity: 1;transform: translateY(-7px);" @click="skipTo('/Recharge')">
				<div class="img recharge"></div>
			</div>
			<div class="item" :class="{'active': name=='Warehouse'}" @click="skipTo('/Warehouse')">
				<div class="img package"></div>
				<span>背包</span>
			</div>
			<div class="item" :class="{'active': name=='Me'}" @click="skipTo('/Me')">
				<div class="img user"></div>
				<span>我的</span>
			</div>
		</div>
	</div>
</template>

<script>
	import PubHeader from '@/components/PubHeader/index.vue'
	import PubFooter from '@/components/PubFooter/index.vue'
	import BackTop from '@/components/BackTop/index.vue'//回到顶部
	import BroadSide from '@/components/BroadSide/index.vue'//红包
	import PubSwiper from '@/components/HeaderSwiper/index.vue'
	export default {
		name: 'Index',
		data() {
			return {
				showFooter: false,
				showSwiper: true,
			}
		},
		components: {
			PubHeader,
			PubFooter,
			BackTop,
			BroadSide,
			PubSwiper
		},
		computed: {
			name(){
				return this.$route.name
			}
		},
		created(){
			if(this.$route.name=='Home'){
				this.showFooter = true
			}else{
				this.showFooter = false
			}

			// if(this.$route.name=='Welfare'||this.$route.name=='Roll'){
			// 	this.showSwiper = false
			// }else{
			// 	this.showSwiper = true
			// }
		},
		watch: {
			$route(to,from){
				if(to.name=='Home'){
					this.showFooter = true
				}else{
					this.showFooter = false
				}

				if(to.name=='Welfare'||to.name=='Roll'){
					this.showSwiper = false
				}else{
					this.showSwiper = true
				}
			}
		},
		methods: {
			skipTo(url){
				this.$router.push(url)
			},
			slideTo(type){
				if(type=='prev'){
					this.$refs.swipe.prev();
				}else{
					this.$refs.swipe.next();
				}
			},
		}
	}
</script>

<style lang="scss">
	@import "@/assets/css/base.scss";
	#Index {
		background:url(~@/assets/img/body-bg.png);
		background-attachment: fixed;
    background-position: top;
    background-size: 100% auto;
		min-height: calc(100vh - 54px);
		position: relative;
		// padding-bottom: 142px;
		padding-bottom: 60px;
		.Router_View {
			// min-height: calc(100vh - 334px);
		}		
	}
	.banner-img{
		height: 108px;
		width: 100%;
	}
	.my-swipe-con{
		position: relative;
		.btn{
			position: absolute;
			left: 7px;
			top: 50%;
			transform: translateY(-50%);
			cursor: pointer;
			background-image: url(~@/assets/img/icon-swiper-left.png);
			background-size: 100% 100%;
			width: 22px;
			height: 22px;
			cursor: pointer;
			filter: brightness(1);
			&.right{
				right: 7px;
				left: auto;
				background-image: url(~@/assets/img/icon-swiper-right.png);
			}
		}
	}
	.nav-con{
		display: flex;
		background: linear-gradient(0deg, #202129 0%, rgba(33, 35, 44, 0.00) 24.49%, rgba(33, 35, 44, 0.00) 69.51%, rgba(43, 127, 168, 0.13) 103.73%);
		height: 78px;
		.item{
			@include HScenter;
			flex-direction: column;
			flex: 1;
			opacity: 0.5;
			cursor: pointer;
			&.active{
				opacity: 1;
			}
			img{
				width: 40px;
				height: 40px;
			}
			span{
				text-shadow: 0px 0px 4px rgba(0, 146, 252, 0.20);
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				background: linear-gradient(180deg, #2D71BF 0%, #32FFF3 100%);
				background-clip: text;
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
			}
		}
	}
	.nav-bottom-con{
		position: fixed;
		bottom: 0;
		width: 375px;
		left: 50%;
		bottom: 0;
		transform: translateX(-50%);
		display: flex;
		background: #1C2024;
		z-index: 10;
		.item{
			flex: 1;
			@include HScenter;
			flex-direction: column;
			cursor: pointer;
			opacity: 0.5;
			&.active{
				opacity: 1;
				.img{
					&.home{
						background-image: url(~@/assets/img/nav-bottom-home-active.png);
					}
					&.shop{
						background-image: url(~@/assets/img/nav-bottom-shop-active.png);
					}
					&.package{
						background-image: url(~@/assets/img/nav-bottom-package-active.png);
					}
					&.user{
						background-image: url(~@/assets/img/nav-bottom-user-active.png);
					}
				}
				span{
					background: linear-gradient(180deg, #44A4FD 0%, #FFF 100%);
					background-clip: text;
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
				}
			}
			.img{
				width: 24px;
				height: 24px;
				background-size: 100% 100%;
				&.recharge{
					width: 55px;
					height: 55px;
					background-image: url(~@/assets/img/nav-bottom-recharge.png);
				}
				&.home{
					background-image: url(~@/assets/img/nav-bottom-home.png);
				}
				&.shop{
					background-image: url(~@/assets/img/nav-bottom-shop.png);
				}
				&.package{
					background-image: url(~@/assets/img/nav-bottom-package.png);
				}
				&.user{
					background-image: url(~@/assets/img/nav-bottom-user.png);
				}
			}
			span{
				color: #7AB0FF;
			}
		}
	}
</style>
